<template>
  <ag-card-container
    :search.sync="search"
    title="Liste des ordres de fabrication"
    fluid
    no-content-padding
  >
    <template #left>
      <v-btn
        style="display: inline-block"
        color="success"
        fab
        x-small
        :loading="loadingExport"
        @click="exportExcel"
      >
        <v-icon>file_download</v-icon>
      </v-btn>
      <v-switch
        v-model="resultWithStats"
        inset
        class="ml-4 "
        hide-details
        style="display: inline-block; vertical-align: middle;"
        label="Statistiques"
      />
    </template>

    <batch-list
      :value="batchList"
      :selected.sync="selectedBatchList"
      :loading="loadingData"
      :search="search"
      :result-with-stats="resultWithStats"
    >
      <template #footer>
        <v-btn
          color="accent"
          outlined
          small
          append
          to="restore"
        >
          Ordres de fabrications clôturés
        </v-btn>
      </template>
    </batch-list>

    <ag-float-button-container left>
      <ag-confirm-dialog
        v-if="selectedBatchList.length"
        title="Confirmation"
        :message="removeMessage"
        cancel-label="Annuler"
        confirm-label="Supprimer"
        validation-btn-color="error"
        text-confirmation="supprimer"
        @confirm="removeSelected"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            :loading="loadingRemove"
            color="error"
            fab
            v-bind="attrs"
            v-on="on"
          >
            <v-icon>delete</v-icon>
            <v-chip
              x-small
              class="px-1 float-chip font-weight-black"
              color="white"
              text-color="error"
              v-text="selectedBatchList.length"
            />
          </v-btn>
        </template>
      </ag-confirm-dialog>
    </ag-float-button-container>
    <ag-float-button-container>
      <ag-confirm-dialog
        v-if="selectedBatchList.length"
        title="Confirmation"
        :message="closeMessage"
        cancel-label="Annuler"
        confirm-label="Clôturer"
        validation-btn-color="error"
        @confirm="closeSelected"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            :loading="loadingRemove"
            color="secondary"
            fab
            v-bind="attrs"
            v-on="on"
          >
            <v-icon>close</v-icon>
            <v-chip
              x-small
              class="px-1 float-chip font-weight-black"
              color="white"
              text-color="black"
              v-text="selectedBatchList.length"
            />
          </v-btn>
        </template>
      </ag-confirm-dialog>
      <v-btn
        to="create"
        append
        color="accent"
        fab
      >
        <v-icon>add</v-icon>
      </v-btn>
    </ag-float-button-container>

    <ag-confirm-dialog
      v-model="forceCloseBatch"
      title="Confirmation"
      :message="forceCloseMessage"
      cancel-label="Annuler"
      confirm-label="Forcer la clôture"
      validation-btn-color="error"
      @confirm="forceCloseSelected"
    />

    <!-- RouterView for modals -->
    <router-view />
  </ag-card-container>
</template>

<script>
import AgCardContainer from 'src/components/common/AgCardContainer'
import AgFloatButtonContainer from 'src/components/common/AgFloatButtonContainer'
import AgConfirmDialog from 'src/components/common/AgConfirmDialog'
import { mapActions } from 'vuex'
import Repositories from 'src/repositories'
import { createExcelFile } from 'src/services/drawExcelService'
import BatchList from '../components/BatchList'

export default {
  name: 'BatchListContainer',
  components: {
    AgCardContainer,
    AgFloatButtonContainer,
    AgConfirmDialog,
    BatchList,
  },
  data: () => ({
    batchList: [],
    selectedBatchList: [],
    search: '',
    loadingData: false,
    loadingRemove: false,
    loadingExport: false,
    resultWithStats: false,
    forceCloseBatch: false,
  }),
  computed: {
    removeMessage () {
      const count = this.selectedBatchList.length
      return `Souhaitez-vous supprimer ${count} ordre(s) de fabrication ! 
      Cette action est irréversible.
      Pour confirmer veuillez saisir le mot supprimer dans le champs ci-dessous`
    },
    closeMessage () {
      const count = this.selectedBatchList.length
      return `Souhaitez-vous clôturer ${count} ordre(s) de fabrication`
    },
    forceCloseMessage () {
      const count = this.selectedBatchList.length
      return `Il reste des pièces en cours dans les ${count} ordre(s) de fabrication sélectionnés si vous poursuivez, ces pièces seront supprimées définitivement`
    },
  },
  watch: {
    $route: {
      immediate: true,
      handler ({ name }) {
        if (name === 'BatchList' || !this.batchList.length) {
          this.fetchData()
        }
      },
    },
    resultWithStats: {
      immediate: false,
      handler () {
        this.fetchData()
      },
    },
  },
  methods: {
    ...mapActions('ui', {
      showSuccessNotification: 'showSuccessNotification',
      showErrorNotification: 'showErrorNotification',
    }),
    async fetchData () {
      try {
        this.loadingData = true
        if (this.resultWithStats) {
          this.batchList = await Repositories.batch.getListWithPartStats({ archived: false })
        }
        else {
          this.batchList = await Repositories.batch.getList({ archived: false })
        }
      }
      catch (error) {
        this.showErrorNotification(Repositories.parseErrorMessage(error))
      }
      finally {
        this.loadingData = false
      }
    },
    async closeSelected () {
      try {
        this.loadingRemove = true
        const count = this.selectedBatchList.length
        await Repositories.batch.close(
          this.selectedBatchList.map(select => select._id),
        )
        this.showSuccessNotification(`Vous avez clôturé ${count} ordre(s) de fabrication`)
      }
      catch (error) {
        if (error.response.data === 'Error: Il reste des pièces en cours') {
          this.forceCloseBatch = true
        }
        else {
          this.showErrorNotification(Repositories.parseErrorMessage(error))
        }
      }
      finally {
        this.loadingRemove = false
        if (!this.forceCloseBatch) {
          this.selectedBatchList = []
          this.fetchData()
        }
      }
    },
    async forceCloseSelected () {
      try {
        this.loadingRemove = true
        const count = this.selectedBatchList.length
        await Repositories.batch.forceClose(
          this.selectedBatchList.map(select => select._id),
        )
        this.showSuccessNotification(`Vous avez clôturé ${count} ordre(s) de fabrication`)
      }
      catch (error) {
        this.showErrorNotification(Repositories.parseErrorMessage(error))
      }
      finally {
        this.loadingRemove = false
        this.selectedBatchList = []
        this.fetchData()
      }
    },
    async removeSelected () {
      try {
        this.loadingRemove = true
        const count = this.selectedBatchList.length
        await Repositories.batch.delete(
          this.selectedBatchList.map(select => select._id),
        )
        this.showSuccessNotification(`Vous avez supprimé ${count} ordre(s) de fabrication`)
      }
      catch (error) {
        this.showErrorNotification(Repositories.parseErrorMessage(error))
      }
      finally {
        this.loadingRemove = false
        this.selectedBatchList = []
        this.fetchData()
      }
    },
    async exportExcel () {
      try {
        this.loadingExport = true
        const exportConfig = {
          columns: [
            { header: 'Numéro', key: 'number' },
            { header: 'Date de création', key: 'creationDate' },
            { header: 'Référence', key: 'partNumber' },
            { header: 'Type de Référence', key: 'partType' },
            { header: 'Quantité initial', key: 'quantity' },
            { header: 'Id', key: '_id' },
          ],
          view: [{ state: 'frozen', xSplit: 1, ySplit: 1 }],
          sheetName: 'Liste des ordres de fabrication',
        }
        if (this.resultWithStats) {
          exportConfig.columns = [
            ...exportConfig.columns,
            ...[
              { header: 'Quantité actuelle', key: 'currentQuantity' },
              { header: 'Finis', key: 'finishCount' },
              { header: 'Rebuts', key: 'rejectedCount' },
              { header: 'Dérogations', key: 'finishCount' },
              { header: 'Retouches', key: 'finishCount' },
            ],
          ]
        }
        const data = this.resultWithStats
          ? this.batchList.map(item => ({
            _id: item._id,
            creationDate: this.moment(item.createdAt).toDate(),
            number: item.number,
            partNumber: item.partNumber.name,
            partType: item.partType.name,
            quantity: item.quantity,
            currentQuantity: item.currentQuantity,
            finishCount: item.finishCount,
            rejectedCount: item.rejectedCount,
            exceptionCount: item.exceptionCount,
            retouchedCount: item.retouchedCount,
          }))
          : this.batchList.map(item => ({
            _id: item._id,
            creationDate: this.moment(item.createdAt).toDate(),
            number: item.number,
            partNumber: item.partNumber.name,
            partType: item.partType.name,
            quantity: item.quantity,
          }))
        await createExcelFile([{ dataToExport: data, config: exportConfig }], 'Liste des ordres de fabrication')
      }
      catch (error) {
        this.showErrorNotification(Repositories.parseErrorMessage(error))
      }
      finally {
        this.loadingExport = false
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.float-chip {
  cursor: pointer;
  position: absolute;
  right: 10px;
  top: 12px;
}
</style>
